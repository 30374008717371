
window.formatProjects = function(data) {

	// We only really care if there is an element to pull classes from
	if (!data.element) {
		return data.text;
	}

	var $element = $(data.element);

	var $wrapper = $('<span></span>');
	$wrapper.addClass($element[0].className);

	$wrapper.text(data.text);

	return $wrapper;

}
window.renderJs = function() {
	$(".selectpicker").each(function(){
		let select2conf = {templateResult:formatProjects};
		let $this = $(this);
		if ($this.data('parent')) {
			select2conf.dropdownParent = $($this.data('parent'));
		}
		$this.select2(select2conf);
	});
	$("#section-zeiterfassung .selectpicker.selectpicker-projects").on('change', function() {
		var $this = $(this);
		var $selected = $(this).find(":selected")
		var title = $selected.text();

		$this.closest('section').find('.panel-heading').children('.zeiterfassung-title').text(title);
		$this.closest('section').find('input.zeiterfassung-projectname').val(title);
		$this.closest('section').find('input.zeiterfassung-gw-pm-id').val($selected.data('pmgwid'));
		var $selectPhasen = $this.closest('section').find('.selectpicker-projects-phase');

		if ($selected.data('phasen') && $selected.data('phasen').length) {
			$selectPhasen.html('<option value="">Keine Phase</option>')
			for (var i in $selected.data('phasen')) {
				var option = $selected.data('phasen')[i];
				$selectPhasen.append('<option value="' + option.id + '" '+($selectPhasen.data('prefill') == option.id ? 'selected' : '')+'>' + option.name + '</option>');
			}
			$selectPhasen.closest('.form-group').show();
		} else {
			$selectPhasen.closest('.form-group').hide();
		}
	}).trigger('change');

	$('#add-project .selectpicker-projects').on('change', function(){
		let $this = $(this);
		var $selected = $(this).find(":selected")
		var title = $selected.text();

		$this.closest('section').find('.panel-heading').children('.zeiterfassung-title').text(title);
		$this.closest('section').find('input.zeiterfassung-projectname').val(title);
		$this.closest('section').find('input.zeiterfassung-gw-pm-id').val($selected.data('pmgwid'));
		var $selectPhasen = $this.closest('section').find('.selectpicker-projects-phase');
		$selectPhasen.val('');
		let hasPhasen = false;
		if ($selected.data('phasen') && $selected.data('phasen').length) {
			$selectPhasen.html('<option value="">Keine Phase</option>')
			for (var i in $selected.data('phasen')) {
				var option = $selected.data('phasen')[i];
				$selectPhasen.append('<option value="' + option.id + '" '+($selectPhasen.data('prefill') == option.id ? 'selected' : '')+'>' + option.name + '</option>');
			}
			$selectPhasen.closest('.form-group').show();
			hasPhasen = true;
		} else {
			$selectPhasen.closest('.form-group').hide();
		}
		if ($this.val()) {
			$this.select2('close');
			var $selectLeistungsart = $this.closest('section').find('.selectpicker-leistungsart');
			if (hasPhasen) {
				$selectPhasen.select2('open');
			} else {
				$selectLeistungsart.select2('open');
			}
		}
	});
	$('#add-project .selectpicker-projects-phase').on('change', function(){
		let $this = $(this);
		if ($this.val()) {
			$this.select2('close');
			let $selectLeistungsart = $this.closest('section').find('.selectpicker-leistungsart');
			$selectLeistungsart.select2('open');
		}
	});

	$('#add-project .selectpicker-leistungsart').on('change', function(){
		let $this = $(this);
		if ($this.val()) {
			$this.select2('close');
			window.setTimeout(function(){
				$this.closest('section').find('.zeiterfassung-text').focus();
			},200);
		}
	});

	if (window.location.hash == '#einstellungen') {
		einstellungen();
	}

	$('#addTrackingByTaskId').on('keypress', function(e){
		var val = $(this).val();
		val = parseInt(val);
		if (val && e.keyCode == 13) {
			addTrackingByTask(val);
			$(this).val('');
		}
	})
}

window.login = function() {
	$('#form-login').on('submit', function(){
		var username = $('#form-login-username').val();
		var password = $('#form-login-password').val();

		if (username && password) {
			AppUser.login(username,password);

		}
		return false;
	});
}

window.logout = function() {
	if (AppUser) {
		AppUser.logout();
	}
}


window._timer = false;
window._timerNotification = false;
window._timerNotificationSeconds = 0;
window.timer = function($section, trigger) {
	// Zeiten werden ab Startdatum berechnet.
	var $inputStarttime = $section.find('.zeiterfassung-starttime');
	var $inputSeconds = $section.find('.zeiterfassung-seconds');
	var $inputTime = $section.find('.zeiterfassung-time');
	var $inputReZeit = $section.find('.zeiterfassung-rezeit');
	var seconds = $inputSeconds.val();
	if ($inputStarttime.val() > 0) {
		seconds = Math.ceil((Date.now() / 1000) - $inputStarttime.val());
	}
	if (!trigger) {
		seconds++;
	}

	$inputSeconds.val(seconds);
	$inputTime.html(secondsToHHMMSS(seconds));
	$inputReZeit.html(calcReZeit(seconds) + ' Std.');
	if (window._slider && window._slider.classList.contains('noUi-target') && !trigger) {
		let $zeiterfassung = $section.closest('.zeiterfassung');
		let color = $zeiterfassung.data('color');
		let index = color.match(/[0-9]+/);
		if (index && index[0]) {
			index = index[0];
			let values = _slider.noUiSlider.get();
			if (!Array.isArray(values)) {
				values = [values];
			}
			if (values[index]) {
				if (index > 0) {
					// wir muesen die des vorherigen dazurechnen
					seconds += parseInt(values[index -1]);
				}
				// wir muessen den slider jede sekunde updaten
				values[index] = seconds;
				let start = parseInt(index)+1;
				for (let i = start; i < values.length; i++) {
					values[i]++;
				}
				_slider.noUiSlider.set(values);
			}
		}
	}


}

window.secondsToHHMMSS = function(seconds) {
	if (!seconds) {
		return '';
	}
	var sec_num = parseInt(seconds, 10); // don't forget the second param
	var hours   = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	var seconds = sec_num - (hours * 3600) - (minutes * 60);

	if (hours   < 10) {hours   = "0"+hours;}
	if (minutes < 10) {minutes = "0"+minutes;}
	if (seconds < 10) {seconds = "0"+seconds;}
	return hours+':'+minutes+':'+seconds;
}

window.secondsToHHMM = function(seconds) {
	if (!seconds) {
		return '';
	}
	var sec_num = parseInt(seconds, 10); // don't forget the second param
	var hours   = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);

	if (hours   < 10) {hours   = "0"+hours;}
	if (minutes < 10) {minutes = "0"+minutes;}
	return hours+':'+minutes;
}

window.calcReZeit = function(seconds) {
	if (seconds < 900) {
		// mindestens 15 Minuten
		seconds = 900;
	}
	var minutes =  Math.ceil(seconds / 60);
	// auf volle 15 minuten aufrunden
	var time = Math.ceil(minutes / 15) * 15;

	var mod = parseFloat("0."+time%60);
	mod = Math.round(mod*100);
	mod = str_pad(mod,2,'0','STR_PAD_RIGHT');
	var time = Math.floor(time/60)+":"+mod;

	return time;
}

window.str_pad = function(input, pad_length, pad_string, pad_type) {
  // From: http://phpjs.org/functions
  // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // + namespaced by: Michael White (http://getsprink.com)
  // +      input by: Marco van Oort
  // +   bugfixed by: Brett Zamir (http://brett-zamir.me)
  // *     example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT');
  // *     returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
  // *     example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH');
  // *     returns 2: '------Kevin van Zonneveld-----'
  var half = '',
    pad_to_go;

  var str_pad_repeater = function (s, len) {
    var collect = '',
      i;

    while (collect.length < len) {
      collect += s;
    }
    collect = collect.substr(0, len);

    return collect;
  };

  input += '';
  pad_string = pad_string !== undefined ? pad_string : ' ';

  if (pad_type !== 'STR_PAD_LEFT' && pad_type !== 'STR_PAD_RIGHT' && pad_type !== 'STR_PAD_BOTH') {
    pad_type = 'STR_PAD_RIGHT';
  }
  if ((pad_to_go = pad_length - input.length) > 0) {
    if (pad_type === 'STR_PAD_LEFT') {
      input = str_pad_repeater(pad_string, pad_to_go) + input;
    } else if (pad_type === 'STR_PAD_RIGHT') {
      input = input + str_pad_repeater(pad_string, pad_to_go);
    } else if (pad_type === 'STR_PAD_BOTH') {
      half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2));
      input = half + input + half;
      input = input.substr(0, pad_length);
    }
  }

  return input;
}

window.addZeiterfassung = function($section, prefill) {
	var projects = SwornApi.call('projects/' + AppUser.data.id + '/json','');
	var oProjects = {};
	if (projects) {
		oProjects = projects;
	}
	var id = 0;
	if ($section.find('.zeiterfassung').length > 0) {
		id = $section.find('.zeiterfassung').length;

		// wenn es mehrere gibt, wollen wir die anderen einklappen
		$section.find('.panel-heading').not('.collapsed').trigger('click');
		// dazu triggern wir noch die project dropdowns, damit wir auf jeden fall einen titel haben
		$section.find('#section-zeiterfassung .selectpicker').trigger('change');
	}
	var _prefill = false;
	if (prefill) {
		if (prefill.starttime && prefill.starttime > 0) {
			// wir haben ein startdatum, das heisst, ein laufender stundenzettel, der nicht pausiert wurde.
			// passiert z.b. durch reload oder schliessen des fensters
			prefill.seconds = Math.ceil(Date.now() / 1000) - prefill.starttime;

		}
		_prefill = prefill;
	}
	if (!_prefill) {
		_prefill = {
			'project': false,
			'phase': false,
			'leistungsart': false,
			'seconds': 0,
			'starttime': 0,
			'title': '',
			'text': '',
			'abrechnungstyp' : false,
			'task_id': false,
			'task': false,
			'stammswornproid' : false,
			'swornproid' : false,
			'phaseswornproid' : false,
			'show_time' : '' + !!window._configuration.show_time
		}
	}
	if (typeof _prefill.task_id === "undefined") {
		_prefill.task_id = false;
	}
	if (typeof _prefill.task === "undefined") {
		_prefill.task = false;
	}
	if(_prefill.task_id && _prefill.task_id != false && _prefill.task_id != 'false') {
		// wenn eine taskID mit uebergeben wurde, holen wir uns an dieser stelle die aktuellen daten
		
		var task = AppApi.call('task', _prefill.task_id);
		if (task.success) {
			if (task.data[0].task_project) {
				_prefill.project = task.data[0].task_project;
			}
			if (!_prefill.text) {
				// wenn wir noch keinen text haben, dann ueberschreiben
				_prefill.text = 'Task: #' + task.data[0].task_id + "\n" + task.data[0].task_subject;
				// html sonderzeichen korrekt setzten
				prefill.text = $('<textarea />').html(prefill.text).text();
			}
			_prefill.task = task.data[0];
			_prefill.task.task_subject = $('<textarea />').html(task.data[0].task_subject).text();
			_prefill.stammswornproid = task.data[0].stammswornproid;
			_prefill.swornproid = task.data[0].swornproid;
			_prefill.phase = task.data[0].phaseswornproid;
			_prefill.show_time = task.data[0].show_time;
			// projekt ist entweder ein kunde oder ein projekt
			// projekt ist hoeher priorisiert
			if (_prefill.swornproid) {
				_prefill.project = _prefill.swornproid;
			}
			else if (_prefill.stammswornproid) {
				_prefill.project = _prefill.stammswornproid;
			}
		}
	}
	/*if (oProjects) {
		let prefilledProject = oProjects.find((project) => project.pm_gw_id === _prefill.project);
		if (prefilledProject && _prefill.project) {
			_prefill.project = prefilledProject.pm_id;
		}
	}*/

	let $savedStundenzettel = $('.saved-stundenzettel');
	let offset = $savedStundenzettel.length;
	let color = id + offset;

	$section.find('.main-content-body').append(loadTemplate('zeiterfassung', {user: AppUser, projects: oProjects, id:id, prefill: _prefill, conf: _configuration, color: color}));

	// falls wir ein prefill hatten, triggern wir den timer einmal
	if (prefill) {
		$section.find('section').each(function(){
			timer($(this), true);
		});
		if (prefill.starttime) {
			// wir muessen den timer starten
			$section.find('section').each(function(){
				starttime = $(this).find('.zeiterfassung-starttime').val();
				if (starttime && starttime > 0) {
					$(this).find('button[data-call="start"]').trigger('click');
				}
			});
		}
	} else {
		// wenn wir nicht ueber ein prefill kommen, wollen wir alle daten einmal speichern
		saveZeiterfassungTimer();
	}

}

window.addBalken = function($section) {
	if (window._slider && window._slider.classList.contains('noUi-target')) {
		// ist bereits initialisiert
		//values = _slider.noUiSlider.get();
		_slider.noUiSlider.destroy();
	}
	$('#balken').remove();
	var projects = SwornApi.call('projects/' + AppUser.data.id + '/json','');
	let timestamp = new Date();
	if (window._date) {
		timestamp = window._date;
	}
	timestamp = Math.ceil(timestamp.getTime() / 1000);
	window._savedStundenzettel = SwornApi.call('timesheets', AppUser.data.id, '/' + timestamp);
	var oProjects = {};
	if (projects) {
		oProjects = projects;
	}
    // string draus machen, damit wir replace nutzen koennen
	let dayhours = '' + _configuration.dayhours;
	if (!dayhours || typeof dayhours === "undefined" || dayhours == 'undefined') {
		dayhours = '8';
	}
	let produktionszeit = '0';
	let dayseconds = parseFloat(dayhours) * 60 * 60;
	dayhours = dayhours.replace(',', '.');
	if (!window._date) {
		window._date = new Date();
	}
	let datemin = new Date();
	datemin.setDate(datemin.getDate() - 2);
	datemin = datemin.toISOString().split('T')[0];
	$('#main-content').prepend(loadTemplate('balken', {user: AppUser, projects: oProjects, conf: _configuration, savedStundenzettel:_savedStundenzettel, dayhours:dayhours, date: window._date.toISOString().split('T')[0], datemin: datemin, produktionszeit: produktionszeit}));
	$('#zeiterfassung-config').html(loadTemplate('zeiterfassungconfig', {conf: _configuration, dayhours:dayhours, date: window._date.toISOString().split('T')[0], datemin: datemin}));

	window._slider = document.getElementById('slider-color');

	window._sliderConfig = {
		start: [dayseconds],
		connect: [true, false],
		step: 1,
		margin: 900,
		padding: [900,0],
		range: {
			'min': [0],
			'max': [dayseconds]
		}
	};
}

window.addZeiterfassungByTel = function(tel) {

	// wir versuchen ein Projekt anhand der Telefonnummer zu finden
	var response = AppApi.call('project','','?tel=' + encodeURIComponent(tel));
	var projects = false;
	if (response.success) {
		projects = response.data;
	}
	var prefill = {
		'project': false,
		'phase': false,
		'leistungsart': false,
		'seconds': 0,
		'title': '',
		'text': '',
		'abrechnungstyp' : false,
		'task_id' : false,
		'stammswornproid' : false,
		'swornproid' : false,
		'phaseswornproid' : false,
		'show_time' : '' + !!window._configuration.show_time
	};
	if (projects) {
		var project = false;
		for (var i in projects) {
			// wir gehen alle projekte durch und nehmen das erste aktive
			if (projects[i]['pm_status'] == 'active' || projects[i]['pm_status'] == 'aktiv') {
				project = projects[i];
				break;
			}
		}
		if (!project) {
			// wenn wir nichts gefunen haben, nehmen wir das erste, damit wir wenigstens den namen haben
			project = projects[0];
		}
		prefill.project = project['pm_id'];

		if (project['contact'] && project['contact']['n_fn'])	{
			prefill['title'] = 'Telefonat: ' + project['contact']['n_fn'];
		}

	}

	addZeiterfassung($('#section-zeiterfassung'), prefill);
	renderJs();

	if (_configuration['prefill-recall-directstart']) {
		// den erstelltzen Stundenzettel direkt starten
		var $last = $('#section-zeiterfassung').find('.zeiterfassung').last();
		$last.find('button[data-call="start"]').trigger('click');
	}
}

/**
 * Load a ejs template.
 *
 * @param name
 * @param object
 *
 * @returns {String}
 */
window.loadTemplate = function(name, object) {
	var tpl = false;
	$.ajax({
		url: "/app/partials/" + name + '.ejs',
		type: 'get',
		async: false,
		success: function(data){
			tpl = data;
		}
	});
	if (tpl) {
    	return ejs.render(tpl.toString(), object);
	}
}

window.setSectionTitle = function(title) {
	$('#section-title').html(title);
}

window.notificationWindow = function() {
	$(document).on('click', function(){
		ipcRenderer.send('show-mainwindow', '');
	});

	ipcRenderer.on('notification', function(event, seconds) {
		clearInterval(_timerNotification);
		if (seconds !== 'stop') {
			_timerNotificationSeconds = seconds;
			_timerNotification = setInterval(function(){
				_timerNotificationSeconds++;
				$('#notification').html(secondsToHHMMSS(_timerNotificationSeconds));
			}, 1000);
		} else {
			$('#notification').html('');
		}
	});
}

window.einstellungen = function() {
	var $form = $('#configurations');
	$form.off('submit').on('submit', function(){
		// die Daten in einem Configuration Array speichern
		var $this = $(this);
		// andere meldungen loeschen
		$this.find('.alert-success').remove();

		// conf wird als erstes durch unsere standard konfiguration gesetzt
		var conf = _configuration;
		if ($this.find('input[name="notification-timer"]').is(':checked')) {
			conf['notification-timer'] = $this.find('input[name="notification-timer"]').val();
		} else {
			conf['notification-timer'] = 0;
		}
		if ($this.find('input[name="remove-saved-entries"]').is(':checked')) {
			conf['remove-saved-entries'] = $this.find('input[name="remove-saved-entries"]').val();
		} else {
			conf['remove-saved-entries'] = 0;
		}
		if ($this.find('input[name="show-abrechnungstyp"]').is(':checked')) {
			// conf['show-abrechnungstyp'] = $this.find('input[name="show-abrechnungstyp"]').val();
			// feld gibt es in sworn.pro nicht mehr.
			conf['show-abrechnungstyp'] = 0;
		} else {
			conf['show-abrechnungstyp'] = 0;
		}
		if ($this.find('input[name="prefill-recall"]').is(':checked')) {
			conf['prefill-recall'] = $this.find('input[name="prefill-recall"]').val();
		} else {
			conf['prefill-recall'] = 0;
		}
		if ($this.find('input[name="prefill-recall-directstart"]').is(':checked')) {
			conf['prefill-recall-directstart'] = $this.find('input[name="prefill-recall-directstart"]').val();
		} else {
			conf['prefill-recall-directstart'] = 0;
		}

		if ($this.find('input[name="show-meinestunden"]').is(':checked')) {
			conf['show-meinestunden'] = $this.find('input[name="show-meinestunden"]').val();
			$('#meinestunden').show();
		} else {
			conf['show-meinestunden'] = 0;
			$('#meinestunden').hide();
		}

		window.store.set('configuration', conf);
		_configuration = conf;

		$this.prepend(loadTemplate('message', {type: 'success', message: 'Einstellungen erfolgreich gespeichert'}));

		window.setTimeout(function(){
			$this.find('.alert').remove();
		},1000)

		return false;
	});
}

window.saveZeiterfassung = function() {
	// jede Minuten wollen wir die Stundenzettel Backupen, falls wir das Program schliessen oder es abstuerzt
	window.setInterval(function(){
		saveZeiterfassungTimer();
	},60000);
}

window.saveZeiterfassungTimer = function(balken = 0) {
	// erstmal alles zuruecksetzten, was wir haben
	_stundenzettel = {};
	$('.zeiterfassung').each(function(){
		var $this = $(this);
		var id = $this.data('id');
		// ist hier noch inhalt drin?
		var $form = $this.find('form');
		if ($form.length) {
			// wir haben noch ein formular
			_stundenzettel[id] = {
				'project'			: $form.find('.selectpicker-projects').val(),
				'phase'				: $form.find('.selectpicker-projects-phase').val(),
				'leistungsart'		: $form.find('.selectpicker-leistungsart').val(),
				'seconds'			: $form.find('.zeiterfassung-seconds').val(),
				'start'				: $form.find('.zeiterfassung-start').val(),
				'starttime'			: $form.find('.zeiterfassung-starttime').val(),
				'title'				: $form.find('.zeiterfassung-title').val(),
				'text'				: $form.find('.zeiterfassung-text').val(),
				'abrechnungstyp'	: $form.find('.zeiterfassung-abrechnungstyp').val(),
				'task_id'			: $form.find('.zeiterfassung-task-id').val(),
				'show_time'			: $form.find('.zeiterfassung-show-time').is(':checked'),
				'timestamp'			: $form.find('.zeiterfassung-timestamp').val(),
				'projectname'		: $form.closest('.panel').find('.zeiterfassung-title').text().trim(),
				'email'				: AppUser.data.email,
			}
		}
	});
	window.store.set('timesheets', _stundenzettel);
	$.post('/saveTimesheets.php', {stundenzettel: _stundenzettel, userid: AppUser.data.id});
	if (!balken) {
		calculateBalken();
	}
}

window.loadActualDay = function() {
	return false;
	if (_configuration && _configuration['show-meinestundenmeinestunden']) {
		$('#meinestunden').show();
	} else {
		$('#meinestunden').hide();
	}

	var response = AppApi.call('meinestunden');
	if (response && response.success && response.today) {
		$('#meinestunden').html('Meine Stunden heute: ' + response.today.formatted);
	}
}

window.loadRequestCounter = function() {
	var response = AppApi.call('requests/counter');
	if (response && response.success && response.requestCounter) {
		$('.requestCounter').html('Requests diesen Monat: ' + response.requestCounter);
	}
}

window.openInBrowser = function() {
	var $this = jQuery(this);
	var href = '"' + $this.data('href') + '"';

	var commands = {
		'darwin': 'open ' + href,
		'win32': 'start chrome.exe ' + href,
		'linux': 'xdg-open ' + href
	};

}

jQuery(document).on('click', '.external-link', openInBrowser);

window.addTrackingByRecall = function(tel, projectId = null) {
	var $section = jQuery('#section-zeiterfassung');

	var prefill = {
		'project': false,
		'phase': false,
		'leistungsart': false,
		'seconds': 0,
		'title': 'Telefonat: ',
		'text': '',
		'abrechnungstyp' : false,
		'stammswornproid' : false,
		'swornproid' : false,
		'phaseswornproid' : false,
		'show_time' : '' + !!window._configuration.show_time
	};

	if(projectId) {
		var response = AppApi.call('project');

		var projects = response.data;

		var project = projects.find(project => project.pm_id == projectId);

		if(!project) return;

		prefill.project = project.pm_id;
	}

	addZeiterfassung($section, prefill);
	renderJs();

	if (_configuration['prefill-recall-directstart']) {
		var $last = $section.find('.zeiterfassung').last();
		$last.find('button[data-call="start"]').trigger('click');
	}

}

window.addTrackingByTask = function(taskId) {
	var $section = jQuery('#section-zeiterfassung');

	var prefill = {
		'project': false,
		'phase': false,
		'leistungsart': false,
		'seconds': 0,
		'title': '',
		'text': '',
		'abrechnungstyp' : false,
		'task_id' : false,
		'stammswornproid' : false,
		'swornproid' : false,
		'phaseswornproid' : false,
		'show_time' : '' + !!window._configuration.show_time
	};

	if(taskId) {
		prefill.task_id = taskId;
		addZeiterfassung($section, prefill);
		renderJs();
		if (_configuration['prefill-recall-directstart']) {
			var $last = $section.find('.zeiterfassung').last();
			$last.find('button[data-call="start"]').trigger('click');
		}
	}
}
window.loadAddressbookData = function() {
	if (!AddressbookData) {
		AddressbookData = SwornApi.call('address/json','');
	}
	if (AddressbookData) {
		return AddressbookData;
	}
	return false;
}


window.loadAddress = function(tel, contact) {
	if (tel || contact) {
		// wir wollen die Suche direkt triggern und schauen, was wir finden
		if (!contact) {
			var contact = SwornApi.call('address/phone/','',tel);
		}
		var timeout = 500;
		if ($('#section-adressbuch').length) {
			// wir brauchen kein timeout
			timeout = 0;
		}
		if (contact && contact['ap_id']) {
			//$('a[href="#adressbuch"]').trigger('click');
			window.setTimeout(function(){
				window.location.href = '#adressbuch';
				window.setTimeout(function(){
					var $section = $('#section-adressbuch');
					var date = new Date();
					var dateObj = {};
					var month = date.getMonth() + 1;
					dateObj.date = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '.' + (month < 10 ? '0' + month : month);
					dateObj.time = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
					var $sectionContent = $section.find('.addressdata-items');
					contact.name = false;
					if (contact.nname || contact.vname) {
						contact.name = (contact.anrede ? contact.anrede + ' ' : '') + (contact.titel ? contact.titel + ' ' : '') + (contact.vname ? contact.vname + ' ' : '') + (contact.nname ? contact.nname + ' ' : '');
					}
					var content = loadTemplate('addressData', {contact: contact, date: dateObj, id: Date.now()});
					$section.find('.panel-heading').not('.collapsed').trigger('click');
					$sectionContent.append(content);
					$('.addressdata-close-all').show();

				},timeout);
			},timeout);
		} else {
			window.setTimeout(function(){
				window.location.href = '#adressbuch';
				window.setTimeout(function(){
					var $section = $('#section-adressbuch');
					var date = new Date();
					var dateObj = {};
					var month = date.getMonth() + 1;
					dateObj.date = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '.' + (month < 10 ? '0' + month : month);
					dateObj.time = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
					var $sectionContent = $section.find('.addressdata-items');
					contact.tel = tel;
					var content = loadTemplate('addressDataNoContact', {contact: contact, date: dateObj, id: Date.now()});
					$section.find('.panel-heading').not('.collapsed').trigger('click');
					$sectionContent.append(content);
					$('.addressdata-close-all').show();

				},500);
			},500);
		}
	}
}

window.addTrackingByCompanyname = function(companyname = null) {
	var $section = jQuery('#section-zeiterfassung');

	var prefill = {
		'project': false,
		'phase': false,
		'leistungsart': false,
		'seconds': 0,
		'title': 'Telefonat: ',
		'text': '',
		'abrechnungstyp' : false,
		'stammswornproid' : false,
		'swornproid' : false,
		'phaseswornproid' : false,
		'show_time' : '' + !!window._configuration.show_time
	};

	if(companyname) {
		var response = AppApi.call('project');

		var projects = response.data;

		var project = projects.find(project => project.pm_title == companyname);

		if(!project) return;

		prefill.project = project.pm_id;
	}

	addZeiterfassung($section, prefill);
	renderJs();

	var $last = $section.find('.zeiterfassung').last();
	$last.find('button[data-call="start"]').trigger('click');

}



/**
 * Users for intern dial list
 */
window.loadIntUsers = function() {
	if (!IntUsers) {
		IntUsers = SwornApi.call('users/json','');
	}
	if (IntUsers) {
		return IntUsers;
	}
	return false;
}

window.clearApiCache = function() {
	var check = confirm('Willst du den API Cache wirklich löschen? Nur notwendig, wenn Projekte hinzugefügt wurden. App wird neugeladen');
	if (check) {
		var response = AppApi.call('cache/flush');
		if (response && response.success) {
			window.location.reload();
		}
	}
}

window.calculateBalken = function(){
	let values = [];
	let connect = [];
	if (_slider.classList.contains('noUi-target')) {
		// ist bereits initialisiert
		//values = _slider.noUiSlider.get();
		_slider.noUiSlider.destroy();
	}

	let savedStundenzettel = window._savedStundenzettel;
	let savedSeconds = 0;
	if (savedStundenzettel.length) {
		for (let s in savedStundenzettel) {
			// pro gespeicherten zettel eine eigene range
			savedSeconds += parseInt(savedStundenzettel[s].seconds);
			values.push(savedSeconds);
		}
	}
	if (savedSeconds) {
		_sliderConfig.padding = [0];
		_sliderConfig.range.min = [0];
	}
	if (Object.keys(_stundenzettel).length) {
		// wenn wir temporaer gespeicherte daten haben wollen wir diese hier laden
		let seconds = savedSeconds;
		for (let i in _stundenzettel) {
			seconds += parseFloat(_stundenzettel[i].seconds);
			values.push(seconds);
		}
	}
	for(let i in values) {
		connect.push(true);
	}
	connect.push(false);

	_sliderConfig.start = values;
	_sliderConfig.connect = connect;
	if (values.length) {
/*		_sliderConfig.tooltips = [];
		if (_configuration.show_time) {
			for (let v in values) {
				_sliderConfig.tooltips.push(function(value){
					return value;
				});
			}
		}*/
		noUiSlider.create(_slider,_sliderConfig);
		if (savedSeconds) {
			//$('.noUi-handle.noUi-handle-lower').hide();
		}
		var noconnect = _slider.querySelectorAll('.noUi-connect');
		var classes = ['c-0-color','c-1-color','c-2-color','c-3-color','c-4-color','c-5-color','c-6-color','c-7-color','c-8-color','c-9-color','c-10-color','c-11-color','c-12-color','c-13-color','c-14-color','c-15-color','c-16-color','c-17-color','c-18-color','c-19-color','c-20-color','c-21-color','c-22-color','c-23-color','c-24-color','c-25-color','c-26-color','c-27-color','c-28-color','c-29-color','c-30-color'];


		let length = window._savedStundenzettel.length;
		for (var i = 0; i < length; i++) {
			//let n = i + offset;
			let n = i;
			noconnect[i].classList.add(classes[n]);
		}
		$('.zeiterfassung').each(function(){
			let $this = $(this);
			let i = $this.index() + length;
			if (noconnect[i]) {
				noconnect[i].classList.add($this.data('color'));
			}

		})
		_slider.noUiSlider.on('update', function(){
			savedSeconds = [];
			let savedStundenzettel = window._savedStundenzettel;
			if (savedStundenzettel.length) {
				for (let s in savedStundenzettel) {
					savedSeconds.push(parseInt(savedStundenzettel[s].seconds));
				}
			}
			let values = _slider.noUiSlider.get();
			if (!Array.isArray(values)) {
				values = [values];
			}
			let changed = false;
			let calculatedSeconds = 0;
			for (let v in values) {
				if (savedSeconds[v]) {
					calculatedSeconds += parseInt(savedSeconds[v]);
				}
				if (savedSeconds[v] && calculatedSeconds != parseInt(values[v])) {
				//if (savedSeconds[v] && parseInt(savedSeconds[v]) != parseInt(values[v])) {
					values[v] = savedSeconds[v];
					changed = true;
				}
				if (savedSeconds[v]) {
					$('.noUi-handle').eq(v).hide();
				}
			}

			if (changed) {
				_slider.noUiSlider.set(values);
			}
			offset = window._savedStundenzettel.length ? 1 : 0;
			let investitionszeit = parseInt(_configuration.dayhours) * 60 * 60;
			let produktionszeitSaved = 0;
			let offeneSekunden = 0;
			$('.zeiterfassung-seconds').each(function(){
				offeneSekunden += parseInt($(this).val());
			});
			if (calculatedSeconds + offeneSekunden > investitionszeit) {
				// wir haben schon mehr stunden als moeglich
				// eine halbe stunde auf die aktuelle zeit rechnen
				//$('#dayhours').val(Math.ceil((calculatedSeconds + offeneSekunden) / 60 / 60) + .5).trigger('change');

			}
			// hier muessen wir die gespeicherten stunden wieder aus den values entfernen, damit wir die sekunden richtig setzen
			for (let s in window._savedStundenzettel) {
				values.shift();
				produktionszeitSaved += window._savedStundenzettel[s].seconds;
			}
			let first = false;
			let prev = calculatedSeconds;
			for(var i in values) {
				let id = parseInt(i);
				var $e = $('.zeiterfassung:not(.zeiterfassung-saved)').eq(id);
				let val = parseInt(values[i]);

				val = val - prev;
				prev = values[i];

				investitionszeit -= val;
				$e.find('.zeiterfassung-seconds').val(val);
				timer($e.find('section'), true);

			}
			investitionszeit -= produktionszeitSaved;
			let produktionszeit = (parseInt(_configuration.dayhours) * 60 * 60) - investitionszeit;
			if (!investitionszeit) {
				$('#investitionszeit').html('00:00 Stunden');
			} else {
				$('#investitionszeit').html(secondsToHHMM(investitionszeit) + ' Stunden');
			}
			if (!produktionszeit) {
				$('#produktionszeit').html('00:00 Stunden');
			} else {
				$('#produktionszeit').html(secondsToHHMM(produktionszeit) + ' Stunden');
			}

			$('.zeiterfassung-starttime').val(0);
			$('.zeiterfassung-starttime').each(function(){
				let $section = $(this).closest('section');
				let seconds = $section.find('.zeiterfassung-seconds').val();
				if ($section.hasClass('time-is-running')) {
					var starttime = Math.ceil(Date.now() / 1000) - parseInt(seconds);
					$(this).val(starttime);
				}
			});
		});

		_slider.noUiSlider.on('change', function(){
			saveZeiterfassungTimer(1);
		});
	}
}
window.recalculateColor = function() {
	let length = window._savedStundenzettel.length;
	$('.zeiterfassung').each(function(){
		let $this = $(this);
		let index = $this.index() + length;
		let color = $this.data('color');
		let newColor = color.replace(/[0-9]+/, index);
		$this.data('color', newColor).find('.panel-heading').removeClass(color).addClass(newColor);

	});
}

window.showLoading = function(){
	$("div.spinner").addClass("show");
	$("div.overlay").addClass("show");
}

window.hideLoading = function(){
	$("div.spinner").removeClass("show");
	$("div.overlay").removeClass("show");
}